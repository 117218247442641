import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div'

import ContainedButton from '../components/ContainedButton'
import Hr from '../components/Hr'
import H4 from '../components/H4'
import H5 from '../components/H5'
import Paragraph from '../components/Paragraph'

import { Container } from '../components/styles/Layout.styles'

import { 
  styledCloudServer,
  header,
} from '../components/styles/Contrato.styles' 

export const PageTemplate = ({
  data,
  ...rest
}) => {
  return (
    <Fragment>
      <section css={styledCloudServer}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H4>Contrato</H4>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pt={40}
            pb={40}
          >
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <H5>Termos do Serviço</H5>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <Paragraph>Nossos Termos de Serviço define regras gerais que devem ser seguidas se você tiver uma conta de hospedagem de qualquer tipo com a gente, incluindo servidores dedicados ou colocated.</Paragraph>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 30
                  }}
                >
                  <ContainedButton
                    type='btnContainedContrato'
                    Link='/termos-de-servico'
                  >
                    Clique aqui para ler
                  </ContainedButton>
                </div>
              </Div>
            </Column>
          </Row>
          <Div 
            alignItems={['center']}
          >
            <Hr
              lineColor='lightPeriwinkle'
              m={['0']}
              maxWidth={[328, '100%' ,680 ,680]}
              style={{
                opacity: 0.5
              }}
            />
          </Div>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pt={40}
            pb={40}
          >
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <H5>Política de Privacidade</H5>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <Paragraph>Privacidade é importante para HostDime. Em nossa Política de Privacidade lhe informamos quais informações coletamos quando você usa nossos produtos e serviços, porque nós coletamos, e como guardamos isso.</Paragraph>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 30
                  }}
                >
                  <ContainedButton
                    type='btnContainedContrato'
                    Link='/politica-de-privacidade'
                  >
                    Clique aqui para ler
                  </ContainedButton>
                </div>
              </Div>
            </Column>
          </Row>
          <Div 
            alignItems={['center']}
          >
            <Hr
              lineColor='lightPeriwinkle'
              m={['0']}
              maxWidth={[328, '100%' ,680 ,680]}
              style={{
                opacity: 0.5
              }}
            />
          </Div>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pt={40}
            pb={40}
          >
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <H5>Política de Cookies</H5>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <Paragraph>Você está no lugar certo para saber mais sobre o uso de cookies do site da HostDime Brasil. A seguir vamos explicar o que são os cookies, quais os tipos de cookies utilizados, para quais finalidades e como você pode exercer o seu direito para configurar seu navegador e rejeitar o uso de qualquer uma delas.</Paragraph>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 30
                  }}
                >
                  <ContainedButton
                    type='btnContainedContrato'
                    Link='/politica-de-cookies'
                  >
                    Clique aqui para ler
                  </ContainedButton>
                </div>
              </Div>
            </Column>
          </Row>
          <Div 
            alignItems={['center']}
          >
            <Hr
              lineColor='lightPeriwinkle'
              m={['0']}
              maxWidth={[328, '100%' ,680 ,680]}
              style={{
                opacity: 0.5
              }}
            />
          </Div>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pt={40}
            pb={40}
          >
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <H5>Política de Qualidade e Meio Ambiente</H5>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <Paragraph>A Política de Qualidade da HostDime, tem como principal propósito o compromisso com o foco no cliente e na sua satisfação, através da qualidade nos serviços prestados, excelência no atendimento e melhoria contínua de seus processos, profissionais e tecnologias.</Paragraph>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 30
                  }}
                >
                  <ContainedButton
                    type='btnContainedContrato'
                    Link='/politica-de-qualidade'
                  >
                    Clique aqui para ler
                  </ContainedButton>
                </div>
              </Div>
            </Column>
          </Row>
          <Div 
            alignItems={['center']}
          >
            <Hr
              lineColor='lightPeriwinkle'
              m={['0']}
              maxWidth={[328, '100%' ,680 ,680]}
              style={{
                opacity: 0.5
              }}
            />
          </Div>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pt={40}
            pb={40}
          >
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <H5>Política de Segurança e Privacidade da Informação e Serviços</H5>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <Paragraph>A Política de Segurança e Privacidade da Informação e Serviços da HostDime, tem como seus principais pilares a confidencialidade, integridade e disponibilidade das informações que estão sob sua responsabilidade. Para isso, são implementados controles de segurança que são rigidamente avaliados, para garantir a proteção das informações e conformidade com seus processos e políticas.</Paragraph>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 30
                  }}
                >
                  <ContainedButton
                    type='btnContainedContrato'
                    Link='/politica-de-seguranca-e-privacidade-da-informacao-e-servicos'
                  >
                    Clique aqui para ler
                  </ContainedButton>
                </div>
              </Div>
            </Column>
          </Row>
          <Div 
            alignItems={['center']}
          >
            <Hr
              lineColor='lightPeriwinkle'
              m={['0']}
              maxWidth={[328, '100%' ,680 ,680]}
              style={{
                opacity: 0.5
              }}
            />
          </Div>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pt={40}
            pb={40}
          >
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <H5>Política de Segurança e Privacidade da Informação para Computação em Nuvem</H5>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <Paragraph>A política de segurança da informação para computação em nuvem da HostDime é uma extensão de sua atual política de segurança da informação, e tem como propósito abordar os principais aspectos que contribuem para a proteção das informações nos serviços de cloud computing providos pela HostDime.</Paragraph>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 30
                  }}
                >
                  <ContainedButton
                    type='btnContainedContrato'
                    Link='/politica-de-seguranca-e-privacidade-da-informacao-para-computacao-em-nuvem'
                  >
                    Clique aqui para ler
                  </ContainedButton>
                </div>
              </Div>
            </Column>
          </Row>
          <Div 
            alignItems={['center']}
          >
            <Hr
              lineColor='lightPeriwinkle'
              m={['0']}
              maxWidth={[328, '100%' ,680 ,680]}
              style={{
                opacity: 0.5
              }}
            />
          </Div>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pt={40}
            pb={40}
          >
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <H5>Política de utilização de tráfego</H5>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <Paragraph>A Política de Uso de Tráfego esclarece como funciona o conceito do método 95% de medição para os produtos da HostDime Brasil.</Paragraph>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 30
                  }}
                >
                  <ContainedButton
                    type='btnContainedContrato'
                    Link='/politica-de-utilizacao-de-trafego'
                  >
                    Clique aqui para ler
                  </ContainedButton>
                </div>
              </Div>
            </Column>
          </Row>
          <Div 
            alignItems={['center']}
          >
            <Hr
              lineColor='lightPeriwinkle'
              m={['0']}
              maxWidth={[328, '100%' ,680 ,680]}
              style={{
                opacity: 0.5
              }}
            />
          </Div>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pt={40}
            pb={40}
          >
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <H5>Política de Continuidade de Negócios</H5>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <Paragraph>A Política de Continuidade de Negócios tem como objetivo assegurar a continuidade das operações da organização na eventualidade de uma interrupção prolongada dos recursos que dão suporte à realização das nossas operações.</Paragraph>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 30
                  }}
                >
                  <ContainedButton
                    type='btnContainedContrato'
                    Link='/politica-de-continuidade-de-negocios'
                  >
                    Clique aqui para ler
                  </ContainedButton>
                </div>
              </Div>
            </Column>
          </Row>  
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => {
  return (
    <Layout>
      <Seo title='Contrato' />
      <PageTemplate
        title='Contrato'
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cloud-server/cloud-server.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default (StaticPage)
